
































































































































































































































import { Component, Vue, Emit, Prop, Watch } from 'vue-property-decorator';

@Component
export default class KiyakuContent extends Vue {
}
